<template>
  <div class="user-collect">
    <div class="block_title">
      <el-row class="collect-header">
        <el-col v-show="fav_type == 1">
          <span class="user-icon"></span>
          <span class="user-title">收藏商品</span>
        </el-col>
        <el-col v-show="fav_type == 0">
          <span class="user-icon"></span>
          <span class="user-title">关注店铺</span>
        </el-col>
        <el-col v-show="fav_type == 1">
          <el-button type="danger" size="small" class="collect-btn" @click="collectStore">关注店铺</el-button>
        </el-col>
        <el-col v-show="fav_type == 0">
          <el-button type="danger" size="small" class="collect-btn" @click="collectGoods">收藏商品</el-button>
        </el-col>
      </el-row>
      <el-row class="x20"></el-row>
      <!-- 收藏商品 -->
      <div class="c-goods" v-show="fav_type == 1">
        <table class="c-tb">
          <thead class="c-td">
            <th>ID</th>
            <th>商品名称</th>
            <th>价格</th>
            <th>操作</th>
          </thead>
          <tbody class="c-bd" v-for="item,index in collectList" :key="index">
            <td class="c-num">{{item.goods_id}}</td>
            <td class="c-goods">
              <img :src="item.goods_image" alt="" />
              <a href="#">{{item.goods_name}}</a>
            </td>
            <td class="t-price">
              <strong>{{item.favlog_price}}</strong>
            </td>
            <td class="t-opt">
              <div class="o-item">查看</div>
              <div class="o-item" @click="deleteCollect(item.favlog_id)">删除</div>
            </td>
          </tbody>
        </table>
      </div>
      <!-- 关注店铺 -->
      <div class="c-store" v-show="fav_type == 0">
        <table class="c-tb">
          <thead class="c-td">
            <th>ID</th>
            <th>店铺名称</th>
            <th>操作</th>
          </thead>
          <tbody class="c-bd" v-for="item,index in collectList" :key="index">
            <td class="c-num">{{item.favlog_id}}</td>
            <td class="c-goods">
              <!-- 店铺图标 -->
              <img src="#" alt="" />
              <a href="#">{{item.store_name}}</a>
            </td>
            <td class="t-opt">
              <div class="o-item">查看</div>
              <div class="o-item" @click="deleteCollect(item.favlog_id)">删除</div>
            </td>
          </tbody>
        </table>
      </div>
    </div>
    <!-- 分页 -->
    <el-pagination @current-change="handleCurrentChange" :current-page.sync="page" :page-size="limit" layout="total, prev, pager, next, jumper" :total="collectCount" style="float: right;">
    </el-pagination>
  </div>
</template>

<script>
  import { getUserCollectListApi, deleteUserCollectApi } from '@/api/user'
  export default {
    name: "userCollect",
    data() {
      return {
        fav_type: 1,
        collectList: [],
        collectCount: 0,
        page: 1,
        limit: 10
      }
    },
    created() {
      this.getUserCollectList()
    },
    methods: {
      // 分页
      handleCurrentChange(val) {
        this.page = val
        this.getUserCollectList()
      },
      // 删除
      deleteCollect(favlog_id) {
        this.$confirm('您确定删除收藏商品/店铺吗？', '删除收藏确认', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteUserCollectApi(favlog_id).then(res => {
            if (res.data.status === 10000) return this.$message.success('删除成功')
            this.$message.error('删除失败')
          })
        }).catch(() => {
          this.$message.info('已取消');
        });
      },
      // 收藏列表
      getUserCollectList() {
        getUserCollectListApi(this.fav_type).then(res => {
          console.log(res)
          this.collectList = res.data.result.list
          this.collectCount = res.data.result.count
        })
      },
      collectStore() {
        this.fav_type = 0
        this.page = 1
        this.getUserCollectList()
      },
      collectGoods() {
        this.fav_type = 1
        this.page = 1
        this.getUserCollectList()
      }
    },
  }
</script>

<style lang="less" scoped>
  .user-collect {
    padding: 20px;

    .collect-header {
      display: flex;
      justify-content: space-between;

      .collect-btn {
        float: right;
        background: #e1251b;
      }
    }

    .user-icon {
      border: 2px solid #e1251b;
    }

    .user-title {
      font-size: 16px;
      padding-left: 20px;
    }

    .x20 {
      height: 1px;
      background: #efefef;
      margin: 20px 0;
    }

    .c-tb {
      box-sizing: border-box;
      text-align: center;

      .c-td {
        height: 50px;
        background: #fafafa;
        color: #666;
      }

      .c-bd {
        height: 80px;
        border-bottom: 1px solid #ddd;
        color: #666;

        .c-num {
          width: 50px;
        }

        .c-goods {
          height: 80px;
          line-height: 80px;

          img {
            width: 50px;
            height: 50px;
            vertical-align: middle;
            margin-right: 10px;
          }
        }

        .t-price {
          color: #e1251b;
        }

        .t-opt {
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;

          .o-item {
            width: 56px;
            height: 30px;
            border: 1px solid #ddd;
            line-height: 30px;
            text-align: center;
            margin-right: 8px;
            border-radius: 3px;
            color: #666;
            cursor: pointer;
          }
        }

        &:hover {
          background: #e6f7ff;
        }
      }
    }

    .c-page {
      margin: 20px 0px;
      display: flex;
      justify-content: flex-end;

      .p-item {
        float: left;
        width: 32px;
        height: 32px;
        border: 1px solid #ddd;
        margin-right: 10px;
        border-radius: 3px;
        line-height: 32px;
        text-align: center;
        font-size: 14px;
      }

      .p-current {
        border: 1px solid #e1251b;
        color: #e1251b;
      }
    }
  }
</style>